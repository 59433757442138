import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Box, Stat, StatLabel, StatNumber, StatHelpText, useTheme, Stack } from "@chakra-ui/react";
import AppIcon from "./app-icon";
import { ReactNode } from "react";

interface Props {
  name: string;
  value: string;
  icon?: IconProp;
  helpText?: string;
  textColor: string;
  children?: ReactNode;
}

export function MetricBox(props: Props) {
  const { name, value, icon, helpText, textColor, children } = props;
  const theme = useTheme();

  return (
    <Box
      paddingX={4}
      paddingY={2}
      minWidth="10rem"
      backgroundColor={theme.colors.white}
      borderRadius={4}
      border={`1px solid ${theme.colors.gray[300]}`}
      display="inline-block"
    >
      <Stat display="flex">
        <StatLabel fontSize="lg">{name}</StatLabel>
        <Stack isInline spacing={1} alignItems="center" marginTop={4}>
          {icon && <AppIcon icon={icon} size="1x" standardRightMargin opacity={0.3} />}
          <StatNumber color={textColor} fontSize="2xl">
            {value}
          </StatNumber>
        </Stack>
        {!!helpText && <StatHelpText color={textColor}>{helpText}</StatHelpText>}
        {!!children && children}
      </Stat>
    </Box>
  );
}
